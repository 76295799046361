/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-protect-your-ears-during-outdoor-concerts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-protect-your-ears-during-outdoor-concerts",
    "aria-label": "how to protect your ears during outdoor concerts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to protect your ears during outdoor concerts?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hero-purple-wave.png",
    alt: "Purple Wave background hero",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Move over Woodstock — we’ve been celebrating music together in the Western Hemisphere during the modern age since at least the 1950s."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But festival culture predates even this: communal experiences go back to ancient Greek sects like the Bacchanals and then down to the Celtic and Gaelic cultures. And Indians have been running the Tyagaraja Aaradhana since the mid-1880s."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s a reason why “festival” sounds so much like “feast”. Both words have the same root and speak of a culture that celebrated the dawn of new seasons, especially the harvest."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Music festivals have since evolved and their cultural signification has changed as well. Initially an exclusive experience meant only for blue bloods with time and money to spare, it moved to being a sign of protest — think, Woodstock — and anarchy and then counter-culture."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Today, it’s a mélange of people, cultures and experiences that put aside all their differences and come together to celebrate music."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As its meaning has changed, so has the technology used to promote and enhance sound at outdoor music festivals. And that means, the risk of hearing loss has shifted significantly since the more “modest” days of old."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "gearing-up-for-a-season-of-808s",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gearing-up-for-a-season-of-808s",
    "aria-label": "gearing up for a season of 808s permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gearing up for a season of 808s"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/concert-80s.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you think “outdoor concerts”, we’re usually thinking food, libations, music, cute guys and girls dressed in funky outfits, glow sticks, lighters and portable toilets."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "But loud music can have a myriad of effects that all start with affecting one’s hearing. This can lead to other conditions such asmigraines, chronic tinnitus (ringing in the ears), hoarse voice boxes and sore throats the next day, as well as more serious neck pain, which can in turn trigger back pain.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the case of tinnitus, the effect on neck pain is a clear connection. Imagine something being pulled or stretched in the wrong position (tinnitus) held for a period of time. The pain of that stretched muscle then gets triggered every time another connected muscle is activated. The same is true for neck movement and tinnitus."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-loud-is-too-loud",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-loud-is-too-loud",
    "aria-label": "how loud is too loud permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How loud is too loud?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/dj.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus and hearing damage are things we don’t normally think about."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our attention and concern is plugged into much more immediate issues – unsafe and aggressive drivers, our friends, our kids, our work. Who’s ever heard of being concerned about hearing loss?"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Well, here are some rather alarming facts: most rock shows are between 110 dB and 120dB. Continuous exposure to noise even as low as 85dB can cause permanent hearing damage.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the WHO, over a billion teenagers (that’s around the population of the subcontinent of India, by the way) and young adults from 12-35 years of age are now at risk because of 3 contributing factors. Yes, you guessed it: noisy events, noise pollution in public venues, and prolonged use of personal audio devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Music is a pleasure all on its own. It’s an evocative, soul-touching experience and it can be easy to get lost in the sound. In order to protect this vital sense, here are 7 things you can do from your side:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Wear hearing protection.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you head out to concert venues, make sure to bring a pair of earbuds — or two pairs. You can also use over-the-ear noise reduction headphones. Make sure to wipe these down at the end of the night ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-externa/",
    className: "c-md-a"
  }, "to avoid infections"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2.Take regular breaks from the environment.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Plan to take a break after a particularly loud set, your favorite band, or even on the hour. Walk away from the venue and move to a quiet spot. If it’s an overnight event, head back to the camping area and you’ll be able to rest up for 5-10 minutes before heading back."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3. Scope the scene.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If it’s an all-day event or only lasts for a couple of hours, why not head there early? This way, you can scope out the stage and speaker set up. Try and head to the middle or keep to the back. If you’re on the side, chances are, you’ll catch a big wave of sound from the speakers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "4. Try to head to outdoor festivals rather than indoor concerts.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you listen to loud music indoors, the vibration and power of the sound is confined within the room. There’s no space for the sound to drift and dissipate. The smaller and more intimate the venue, the more at risk you are for noise-induced hearing problems. So try and head to an outdoor venue instead."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "5. Use the 60:60 rule.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is an easy one that you can use whether you’re at an outdoor concert or even just listening to your personal music device. Listen to music at 60% volume for no more than 60 minutes at a time, with a 60 minute break in between plays."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "6. Give yourself a hearing detox.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A day before the concert is scheduled to start, why not give yourself a hearing detox. Still all noises in the house and pick up a book instead. Commit yourself to a day of silence and meditate. Close all doors and windows and relax. This will relax your mind as well as give your delicate ear drums a break.You can also run a detox after the event. Give yourself a full day — Action on Hearing Loss suggests at least 16 hours for every two hours of loud music the day before."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "7. Visit a hearing specialist before and after a festival.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/about-us/network/",
    className: "c-md-a"
  }, "Visit a professional"), " before the concert to establish your baseline hearing. If you’ve been experiencing the effects of a blocked ear drum, then this can be solved at this point. After the concert, head back to the specialist to make sure that your hearing levels are on par."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "recovering-from-a-concert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#recovering-from-a-concert",
    "aria-label": "recovering from a concert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Recovering From a Concert"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/microphone.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Noise can affect our hearing")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s not about eliminating noise but about reducing noise and “conserving” our hearing. Think of it like having a daily or weekly quota of hearing. As long as you remain under or even stretch to being at capacity, you can listen without incurring long-term damage. Every prolonged hearing session counts toward your limit so “listen” wisely.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Keep ears free of blockage")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "Earwax naturally falls out of the ear canal"), ", so there’s no need to be aggressive or invasive with Q-tips. These can affect the small and ", React.createElement(_components.a, {
    href: "/resources/",
    className: "c-md-a"
  }, "delicate hairs of the inner ear"), ", especially if pushed too far."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing a sensation of muffled sounds, you might have impacted ear wax blocking your canal. If this is the case, make sure to visit a healthcare professional and they can remove it carefully."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Playing white noise or relaxing sounds")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After a concert full of louder-than-usual sounds, over a prolonged period, what your ears need are a healing “counter” sound — white noise. White noise cancels out the sharp highs and lows of loud music. It works by reducing the difference between background sounds and those “peak” noises."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Limit how much alcohol you drink")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Experiencing aural fullness or ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/pressure/",
    className: "c-md-a"
  }, "pressure in the ear canal"), " is quite a common side effect of prolonged exposure to loud music. But alcohol increases blood pressure, which increases the flow of blood to your ears, the area behind, the tops of your eyes and the base of your neck. This can exacerbate the effect of ringing and pressure in your ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Music is one of those rare pleasures that cut right to the heart of human emotionality, latch on, and never let go. We can use music to unwind, de-stress, enhance the meaning of art, speak to us and evoke higher states such as love and compassion. We can use it to convey profound values of social justice and to set a mood."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
